export const faqs = [
  {
    id: 1,
    title: "Generate copy in seconds ?",
    content:
      "Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium totam rem aperiam eaque quae abillo inventore veritatis",
    active: true,
  },
  {
    id: 2,
    title: "Why Importance Of Copy.Ai ?",
    content:
      "Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium totam rem aperiam eaque quae abillo inventore veritatis",
    active: false,
  },
  {
    id: 3,
    title: "Need Any Team Members ?",
    content:
      "Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium totam rem aperiam eaque quae abillo inventore veritatis",
    active: false,
  },
  {
    id: 4,
    title: "What Is Artificial Intelligence ?",
    content:
      "Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium totam rem aperiam eaque quae abillo inventore veritatis",
    active: false,
  },
  {
    id: 5,
    title: "Why We Get Artificial Intelligence Services ?",
    content:
      "Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium totam rem aperiam eaque quae abillo inventore veritatis",
    active: false,
  },
];

export const faqs2 = [
  {
    id: 1,
    title: "Generate copy in seconds ?",
    content:
      "To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage find fault chooses",
  },
  {
    id: 2,
    title: "Why Importance Of Copy.Ai ?",
    content:
      "To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage find fault chooses",
  },
  {
    id: 3,
    title: "What Is Artificial Intelligence ?",
    content:
      "To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage find fault chooses",
  },
  {
    id: 4,
    title: "How Much Pricing For Copy Website ?",
    content:
      "To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage find fault chooses",
  },
  {
    id: 5,
    title: "Enter your post’s title and keyword ?",
    content:
      "To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage find fault chooses",
  },
  {
    id: 6,
    title: "Review your outline and main talking points ?",
    content:
      "To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage find fault chooses",
  },
  {
    id: 7,
    title: "Generate your first draft ?",
    content:
      "To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage find fault chooses",
  },
  {
    id: 8,
    title: "Edit, polish, and publish ?",
    content:
      "To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage find fault chooses",
  },
];

export const faqs3 = [
  {
    id: 1,
    title: "Generate copy in seconds ?",
    content:
      "Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium totam rem aperiam eaque quae abillo inventore veritatis",
  },
  {
    id: 2,
    title: "Why Importance Of Copy.Ai ?",
    content:
      "Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium totam rem aperiam eaque quae abillo inventore veritatis",
  },
  {
    id: 3,
    title: "Need Any Team Members ?",
    content:
      "Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium totam rem aperiam eaque quae abillo inventore veritatis",
  },
  {
    id: 4,
    title: "What Is Artificial Intelligence ?",
    content:
      "Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium totam rem aperiam eaque quae abillo inventore veritatis",
  },
  {
    id: 5,
    title: "Why We Get Artificial Intelligence Services ?",
    content:
      "Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium totam rem aperiam eaque quae abillo inventore veritatis",
  },
];
export const faqs4 = [
  {
    id: 1,
    title: "Generate copy in seconds ?",
    content:
      "Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium totam rem aperiam eaque quae abillo inventore veritatis",
  },
  {
    id: 2,
    title: "Why Importance Of Copy.Ai ?",
    content:
      "Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium totam rem aperiam eaque quae abillo inventore veritatis",
  },
  {
    id: 3,
    title: "Need Any Team Members ?",
    content:
      "Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium totam rem aperiam eaque quae abillo inventore veritatis",
  },
  {
    id: 4,
    title: "What Is Artificial Intelligence ?",
    content:
      "Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium totam rem aperiam eaque quae abillo inventore veritatis",
  },
  {
    id: 5,
    title: "Why We Get Artificial Intelligence Services ?",
    content:
      "Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium totam rem aperiam eaque quae abillo inventore veritatis",
  },
];
