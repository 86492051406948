export const partners = [
  {
    id: 1,
    src: "/assets/images/partners/spotify-svg.png",
    alt: "Spotify",
    className: "item-1",
  },
  {
    id: 2,
    src: "/assets/images/partners/dropbox-svg.png",
    alt: "Dropbox",
    className: "item-2",
  },
  {
    id: 3,
    src: "/assets/images/partners/google-svg.png",
    alt: "Google",
    className: "item-3",
  },
  {
    id: 4,
    src: "/assets/images/partners/airbnb-svg.png",
    alt: "Airbnb",
    className: "item-4",
  },
  {
    id: 5,
    src: "/assets/images/partners/envato-svg.png",
    alt: "Envato",
    className: "item-5",
  },
  {
    id: 6,
    src: "/assets/images/partners/spotify-svg.png",
    alt: "Spotify",
    className: "item-1",
  },
  {
    id: 7,
    src: "/assets/images/partners/dropbox-svg.png",
    alt: "Dropbox",
    className: "item-2",
  },
  {
    id: 8,
    src: "/assets/images/partners/google-svg.png",
    alt: "Google",
    className: "item-3",
  },
  {
    id: 9,
    src: "/assets/images/partners/airbnb-svg.png",
    alt: "Airbnb",
    className: "item-4",
  },
  {
    id: 10,
    src: "/assets/images/partners/envato-svg.png",
    alt: "Envato",
    className: "item-5",
  },
];

export const partners2 = [
  {
    id: 1,
    src: "/assets/images/partners/spotify-svg-white.png",
    alt: "Spotify",
  },
  {
    id: 2,
    src: "/assets/images/partners/dropbox-svg-white.png",
    alt: "Dropbox",
  },
  {
    id: 3,
    src: "/assets/images/partners/google-svg-white.png",
    alt: "Google",
  },
  {
    id: 4,
    src: "/assets/images/partners/airbnb-svg-white.png",
    alt: "Airbnb",
  },
  {
    id: 5,
    src: "/assets/images/partners/envato-svg-white.png",
    alt: "Envato",
  },
  {
    id: 6,
    src: "/assets/images/partners/spotify-svg-white.png",
    alt: "Spotify",
  },
  {
    id: 7,
    src: "/assets/images/partners/dropbox-svg-white.png",
    alt: "Dropbox",
  },
  {
    id: 8,
    src: "/assets/images/partners/google-svg-white.png",
    alt: "Google",
  },
  {
    id: 9,
    src: "/assets/images/partners/airbnb-svg-white.png",
    alt: "Airbnb",
  },
  {
    id: 10,
    src: "/assets/images/partners/envato-svg-white.png",
    alt: "Envato",
  },
];
export const partnersImages = [
  {
    id: 1,
    className: "item-2",
    src: "/assets/images/partners/sublime.png",
    alt: "",
  },
  {
    id: 2,
    className: "item-3",
    src: "/assets/images/partners/cloud.png",
    alt: "",
  },
  {
    id: 3,
    className: "item-4",
    src: "/assets/images/partners/gliastudio.png",
    alt: "",
  },
  {
    id: 4,
    className: "item-5",
    src: "/assets/images/partners/spotify.png",
    alt: "",
  },
  {
    id: 5,
    className: "item-6",
    src: "/assets/images/partners/python.png",
    alt: "",
  },
  {
    id: 6,
    className: "item-7",
    src: "/assets/images/partners/visual.png",
    alt: "",
  },
];
