export const imageSources = [
  "/assets/images/avatar/avatar-small-02.webp",
  "/assets/images/avatar/avatar-small-03.webp",
  "/assets/images/avatar/avatar-small-01.webp",
  "/assets/images/avatar/avatar-small-04.webp",
  "/assets/images/avatar/avatar-small-05.webp",
  "/assets/images/avatar/avatar-small-06.png",
  "/assets/images/avatar/avatar-small-07.png",
  "/assets/images/avatar/avatar-small-08.webp",
];
