export const menuItems = [
  "Features",
  "Integrations",
  "Pricing",
  "Changelog",
  "Roadmap",
];

export const menuItems2 = [
  "About Us",
  "Community",
  "Careers",
  "Affiliate Program",
];
export const menuItems3 = [
  "Download Ai",
  "Setting & Privacy",
  "FAQs",
  "Contact Us",
];
